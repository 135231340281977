<template>
  <div>
    <div class="tab">
      <div class="tabBtnItems">
        <button
          class="tabItem"
          :class="{ selectItem: selectItem === '可信存证校验' }"
          @click="changeItem('可信存证校验')"
        >
          可信存证校验
        </button>
        <button
          @click="changeItem('分享查询')"
          class="tabItem"
          :class="{ selectItem: selectItem === '分享查询' }"
        >
          分享查询
        </button>
      </div>
    </div>

    <component
      :is="comName"
      @comChange="handleChange"
      :successData="successData"
      :selectItem="selectItem"
      :labelPosition="labelPosition"
    ></component>
  </div>
</template>

<script>
// import Header from "../baasIndex/chilCpn/Header.vue";
// import Footer from "../baasIndex/chilCpn/Footer.vue";
import { watchScreenWidth } from "@/utils/watchScreenWidth.js";
export default {
  components: {
    Form_ver: () => import("./childCpn/Form_ver"),
    success_ver: () => import("./childCpn/Success_ver"),
    fail_ver: () => import("./childCpn/Fail_ver"),
    ShareQuery: () => import("./childCpn/ShareQuery"),
    ShareSuccess: () => import("./childCpn/ShareSuccess"),
    // Header,
    // Footer,
  },
  data() {
    return {
      labelPosition: "left",
      comName: "form_ver",
      successData: {},
      selectItem: "可信存证校验",
    };
  },
  methods: {
    // 点击提交按钮切换组件
    handleChange(data) {
      const { comName, successData, isShare } = data;

      if (successData) {
        this.successData = successData;
      }

      this.comName = comName;
      if (comName == "ShareSuccess" || isShare) {
        this.selectItem = "分享查询";
      }
    },
    changeItem(data) {
      console.log(data);
      if (data === "分享查询") {
        this.comName = "ShareQuery";
      } else {
        this.comName = "form_ver";
      }
      this.selectItem = data;
    },
    initScreenWidth() {
      // 判断初始屏幕大小
      if (document.body.clientWidth < 520) {
        this.labelPosition = "top";
      } else {
        this.labelPosition = "left";
      }
      // 监听屏幕是否大于520px
      watchScreenWidth(
        520,
        // 小于520px时
        () => {
          this.labelPosition = "top";
        },
        // 大于520px时
        () => {
          this.labelPosition = "left";
        }
      );
    },
  },

  mounted() {
    this.initScreenWidth();
  },
};
</script>

<style lang='less'>
.center_box {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  .btnSub {
    width: 200px;
    margin-left: -100px;
    background-color: #3d6df1;
  }
}

@media screen and(min-width:520px) {
  .tab {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    .tabBtnItems {
      width: 240px;
      display: flex;
      justify-content: space-between;
    }
    .tabItem {
      width: 110px;
      height: 36px;
      border: 1px solid #3d6df181;
      color: #3d6df1;
      border-radius: 5px;
      &:hover {
        color: #fff;
        background-color: #3d6df1;
      }
    }
    .selectItem {
      color: #fff;
      background-color: #3d6df1;
    }
  }
}

@media screen and (max-width: 768px) and(min-width:520px) {
  .center_box {
    margin-top: 1.375rem;
  }
}

@media screen and (max-width: 520px) {
  .tab {
    .tabBtnItems {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      .tabItem {
        flex: 1;
        height: 55px;
        border-bottom: 2px solid transparent;
        &:hover {
          color: #387efd;
          border-bottom: 2px solid #387efd;
        }
      }
      .selectItem {
        color: #387efd;
        border-bottom: 2px solid #387efd;
      }
    }
  }
  .center_box {
    margin: 1.375rem auto;
    padding: 20px;
    .btnSub {
      margin-left: 0;
    }
  }
}
</style>